.scroll-to-top {
    position: fixed;
    bottom: 60px;
    left: 20px;
    background-color: var(--ite-color-primary) !important;
    color: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: opacity 0.3s;
    opacity: 0.8;
    z-index: 999 !important;
}

.scroll-to-top:hover {
    opacity: 1;
}