:root {
  --ite-color-primary: #972069;
}


.margin-bottom-1 {
  margin-bottom: 80px;
}


.margin-bottom-2 {
  margin-bottom: 150px;
}


.centered-text-0 {
  text-align: center;
  white-space: nowrap;
  margin: 50px 0;
}


html {
  min-height: 100%;
  position: relative;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  text-align: justify;
}

a {
  cursor: pointer;
}

.bg-ite {
  background: var(--ite-color-primary);
}

li::marker {
  color: var(--ite-color-primary);
  font-weight: bolder;
  font-size: 20px;
}

/* Typography */
h1,
h2,
h3,
h4,
h5,
.text-strong,
strong {
  color: var(--ite-color-primary);
  font-weight: bolder !important;
  -webkit-text-stroke: 2px var(--ite-color-primary);
  letter-spacing: 3px;
}

.hr-bottom {
  border-bottom-style: dotted;
  border-bottom-color: var(--ite-color-primary);
  border-bottom-width: 5px;
  border-top: none;
  opacity: 1;
}

.bottom_title {
  margin: 10px 0 15px 0 !important;
  border-bottom-width: 2px;
  border-bottom-style: dashed;
  border-bottom-color: var(--ite-color-primary);
  border-top: none;
}

/* Widths */
.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

/* Button Styles */
.btn-ite {
  --bs-btn-color: var(--ite-color-primary);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #aa0787;
  --bs-btn-border-color: #aa0787 !important;
  --bs-btn-border-radius: 0 !important;
  --bs-btn-hover-border-color: var(--ite-color-primary);
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #aa0787;
  --bs-btn-active-border-color: var(--ite-color-primary);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #9d3c8a;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #9d3c8a;
  --bs-gradient: none;
}

/* Hover Effects */
.hover-ite {
  border-radius: 10px;
  box-shadow: 5px 5px 5px #a0a0a0;
}

.hover-ite:hover {
  transition: 0.5s;
  transform: scale(1.03);
}

/* Content Grid */
.Content__grid,
.Grid__folder,
.Grid__four,
.Grid__five,
.Grid__three {
  display: grid;
  color: var(--ite-color-primary);
  text-align: center;
  font-weight: bolder;
  font-size: 15px;
}


.Grid__four p,
.Grid__three p {
  margin: 5px 0;
}

.Content__grid {
  font-size: 15px !important;
}

.Content__grid img:hover,
.Grid__folder img:hover {
  transition: 0.5s;
  transform: scale(1.2);
}

/* Accordion */
.acordionn-body-ite {
  padding: 0 !important;
}

/* Container */
.App__container {
  margin: auto;
  text-align: justify;
}

/* Gallery */
.Galeria__responsive {
  margin: 1rem auto;
  width: 100%;
  max-width: 960px;
  column-count: 3;
  column-gap: 20px;
  row-gap: 20px;
}

.Galeria__responsive-item {
  margin: 0 0 20px 0;
  break-inside: avoid;
  page-break-inside: avoid;
}

/* Miscellaneous */
._2aOn7 {
  color: var(--ite-color-primary) !important;
  font-weight: bolder !important;
}

._106d2 {
  background: var(--ite-color-primary) !important;
  font-weight: bolder !important;
  border-radius: 0 0 10px 10px !important;
}

._SKh-V,
._2v-d4 {
  background: var(--ite-color-primary) !important;
  font-weight: bolder !important;
  color: #dddddd !important;
  border-radius: 10px 10px 0 0 !important;
}

/* Modal */
.modal-header {
  background: var(--ite-color-primary) !important;
}

.modal-title,
.accordion-button {
  color: #ffffff !important;
}

/* Table Styles */

#Box {
  padding: 10px;
  width: 100%;
  text-align: center;
}

#Box .row .col-md-6 {
  margin: 5px 0;
}

table thead tr th,
table tfoot tr td,
.MuiFormControl-root,
.MuiInputBase-root {
  background-color: var(--ite-color-primary) !important;
  color: #ffffff !important;
}

table thead tr th .MuiSvgIcon-root {
  color: #ffffff !important;
}

.MuiToolbar-root,
.MuiBoox-root,
.MuiButtonBase-root,
table tbody tr td .MuiSvgIcon-root {
  color: var(--ite-color-primary) !important;
}

/* Carousel Styles */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ba1678' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ba1678' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.carousel-indicators [data-bs-target] {
  background-color: var(--ite-color-primary) !important;
  height: 7px !important;
  border: 1px solid var(--ite-color-primary) !important;
  border-radius: 10px !important;
  box-shadow: 3px 2px #000000 !important;
  width: 40px !important;
  margin-right: 5px !important;
  margin-left: 5px !important;
}

/* Media Queries */

/* Desktop */
@media only screen and (min-width: 1024px) {
  .App__container {
    width: 75%;
    padding-bottom: 80px;
  }

  .banner-header {
    font-size: 2.4em;
    padding: 0.5em 1em;
    letter-spacing: 1px;
  }

  #breadcrumbID {
    width: 98vw;
    position: relative;
    left: -16.3%;
    top: -20px !important;
  }

  #carouselTopIndex {
    width: 98vw;
    position: relative;
    left: -16.9%;
    top: -16px !important;
  }

  .Content__grid img {
    width: 62px !important;
  }

  .Grid__folder {
    grid-template-columns: auto auto auto auto auto auto;
    gap: 20px;
  }

  .Grid__folder img {
    width: 130px !important;
  }

  .Content__grid,
  .Grid__four {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
  }

  .Grid__three {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }

  .Grid__five {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
  }
}

/* Tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .App__container {
    width: 85%;
    padding-bottom: 120px;
  }

  #breadcrumbID {
    width: 97.1vw;
    position: relative;
    left: -8.1%;
    top: -20px !important;
  }

  #carouselTopIndex {
    width: 97vw;
    position: relative;
    left: -7%;
  }

  .Content__grid img {
    width: 52px !important;
  }

  .Grid__folder {
    grid-template-columns: auto auto auto auto;
    gap: 20px;
  }

  .Content__grid,
  .Grid__four,
  .Grid__three {
    grid-template-columns: auto auto auto;
    gap: 20px;
  }
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .App__container {
    width: 90%;
    padding-bottom: 130px;
    margin-bottom: 20px;
  }

  .banner-header {
    font-size: 1.4em;
    padding: 0.1em 0.5em;
    letter-spacing: 1px;
  }

  #breadcrumbID {
    width: 98.5vw;
    position: relative;
    left: -4.8%;
    top: -20px !important;
  }

  #carouselTopIndex {
    width: 97vw;
    left: -4.5%;
  }

  .Content__grid img {
    width: 42px !important;
  }

  .Grid__folder {
    grid-template-columns: auto auto;
    gap: 20px;
  }

  .Content__grid,
  .Grid__four,
  .Grid__three {
    grid-template-columns: auto auto;
    gap: 20px;
  }

  .btn-ite {
    font-size: 10px !important;
    padding: 2px !important;
  }
}